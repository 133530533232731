@import 'mixins';


::selection{
    background-color: $main-red;
    color: white;
}
*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.order-button{
    cursor: pointer;
}

html{
    scroll-behavior: smooth;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: $robot;
}

.third-header{
    width: 100%;
    @include flexColumn(flex-start);
    align-items: center;
    font-weight: 400;
    // letter-spacing: ;
    font-size: 30px;
    color: $main-dark;
    text-align: center;
}

h3::before{
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: $main-red;
    border-radius: 5px;
    // margin-right: 10px;
    margin-bottom: 10px;
}

h3::after{
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: $main-red;
    border-radius: 5px;
    // margin-right: 10px;
    margin-top: 10px;
}

.site-header{
    // position: fixed;
    // z-index: 2;
    width: 100%;
    height: 100px;
    background-color: white;
    // background-color: red;
    @include flexRow(center);
    // margin-top: -100px;
    box-shadow: 0px 1px 5px 0px rgb(88, 88, 88);
    z-index: 9999;

    &__container{
        width: 1180px;
        height: 100%;
        // background-color: yellow;    
        @include flexRow(flex-start);    
    }

    &__mobile-button{
        display: none;
    }

    &__nav{
        width: 100%;
        height: 100%;
        // background-color: pink;
        @include flexRow(space-between);
    }

    &__logo-block{
        width: 200px;
        height: 100%;
        cursor: pointer;
        transition: 0.3s;
        // background-color: plum;
        @include flexColumn(center);
        &:hover{
            transform: scale(1.1);
        }
    }

    &__logo-name{
        // background-color: pink;
        text-align: center;
        font-size: 14px;
        @include flexRow(center);
        color: $main-dark;
        font-weight: 500;
        text-align: center;
    }

    &__logo-accent-name{
        color: $main-red;
        margin-right: 4px;
    }
    
    &__menu{
        // width: 600px;
        height: 100%;
        @include flexRow(space-between);
        // background-color: plum;
        align-items: center;
        list-style: none;
    }

    &__menu-item{
        padding: 0px 10px 0px 10px;
        margin: 0px 10px 0px 10px;
        // background-color: orange;
        text-align: center;
        height: 100%;
        @include flexColumn(center);

        &:first-child{
            margin: 0px 50px 0px 0px;
        }

        &:last-child{
            margin: 0px 0px 0px 10px;
            // padding: 0px;
            // display: none;
        }

    }

    &__menu-link{
        text-decoration: none;
        color: black;

        &:hover{
            transform: scale(1.1);
            color: $main-red;
        }
    }

    &__cats-menu{
        // background-color: pink;
        position: relative;
        &:hover{
            .site-header__cats-list{
                display: flex;
            }
        }
    }
    
    &__cats-list{
        background-color: white;
        position: absolute;
        // background-color: pink;
        top: 90px;
        z-index: 1;
        width: 220px;
        // width: 100%;
        list-style: none;
        @include flexColumn(flex-start);
        display: none;
    }

    &__cats-item{
        width: calc(100% - 10px);
        // background-color: plum;
        margin-bottom: 5px;
        font-size: 14px;
        padding: 2px 5px 2px 5px;
        text-align: start;
    }
    
    &__cats-link{
        color: $main-dark;
        text-decoration: none;
        &:hover{
            color: $main-red;
            text-decoration: underline;
        }
    }

    &__calling-time{
        // background-color: pink;
        margin-top: 5px;
        color: $main-red;
        font-size: 18px;
        font-weight: 600;
    }

    .order-button{
        width: 200px;
        height: 50px;
        // background-color: aquamarine;
        background-color: $main-dark;
        border-radius: 30px;
        @include flexColumn(center);
        overflow: hidden;
        text-align: center;
        color: white;
        letter-spacing: 1px;
        font-weight: 500;
        transition: 0.3s;
        position: relative;
        // padding: 0px 10px 0px 10px;
        
        &:hover{
            // transform: ;
            color: $main-red;
        }
    }

    @keyframes orderButton {
        0%{margin-left: -70px;}
        60%{margin-left: -70px;}
        100%{margin-left: 210px;}
    }

    .order-button::before{
        content: "";
        display: block;
        width: 50px;
        height: 100px;
        background-color: $main-red;
        position: absolute;
        margin-left: -70px;
        transform: rotate(20deg);
        animation: orderButton 2s infinite;
    }
}

main{
    // margin-top: 100px;
}

.main-section{
    width:100%;
    height:700px;
    margin-bottom:50px;
    background-color:#ececec;
    display:flex;
    flex-direction:row;
    justify-content:center;
    // position:relative
}

.main-section__slide{
    width:100%;
    height:100%;
    display:flex;
    flex-direction:row;
    justify-content:center
}

.main-section__slide-cover{
    background-color:rgba(0,0,0,.548);
    width:100%;
    height:100%;
    z-index:-1;
    position:absolute
}

.main-section__slide-img{
    width:100%;
    height:100%;
    z-index:-2;
    position:absolute;
    display:flex;
    flex-direction:row;
    justify-content:center
}

.main-section__slide-img img{
    width:100%;
    height:100%;
    -o-object-fit:cover;
    object-fit:cover
}

.main-section__container{
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center
}

.main-section__header{
    width:100%;
    text-align:center;
    margin-top:230px;
    font-weight:400;
    font-size:36px;
    color:#fff
}

.main-section__description-text{
    margin-top:20px;
    width:600px;
    letter-spacing:1px;
    text-align:center;
    color:#fff
}

.main-section__button{
    width:230px;
    height:50px;
    background-color:#3f4f5e;
    margin-top:20px;
    color:#fff;
    text-decoration:none;
    text-align:center;
    display:flex;
    flex-direction:column;
    justify-content:center;
    font-size:18px;
    border-radius:30px;
    cursor:pointer;
    transition:.3s
}

.main-section__button:hover{
    transform:scale(1.1);
    box-shadow:0px 0px 0px 2px #d62229;
}

.services-section{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    padding:0px 0px 40px 0px
}

.services-section__container{
    width:1180px;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start
}

.services-section__services-container{
    width:100%;
    margin-top:30px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    flex-wrap:wrap;
    position: relative;
}


.hidden-serv{
    position: absolute;
    visibility: hidden;
}

.services-section__service-block{
    width:140px;
    height:150px;
    border-radius:5px;
    padding:0px 0px 10px 0px;
    margin:10px;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    text-decoration:none;
    transition:.3s
}

.services-section__service-block:hover{
    transform:scale(1.1);
    box-shadow:0px 0px 3px 0px rgba(0,0,0,.295)
}

.services-section__service-img-block{
    width:100%;
    height:90px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center
}

.services-section__service-img{
    max-width:70%;
    max-height:70%
}

.services-section__service-text{
    text-align:center;
    text-decoration:none;
    color:#3f4f5e;
    margin-top:5px
}

.counters-section{
    width: 100%;
    // height: 250px;
    // background-color: red;
    @include flexRow(center);
    // display: none;
    flex-wrap: wrap;

    &__container{
        width: 1180px;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(center);
    }

    &__counters-container{
        margin-top: 50px;
        width: 100%;
        // margin-left: 10px;
        // margin-right: 10px;
        @include flexRow(center);
        flex-wrap: wrap;
        align-items: center;
    }

    &__counter-block{
        width: 230px;
        height: 100%;
        // background-color: pink;
        @include flexColumn(flex-start);
        align-items: center;
        // margin-bottom: ;
        margin: 0px 10px 30px 10px;
    }

    &__counter-number{
        width: 100%;
        height: 50px;
        // background-color: plum;
        text-align: center;
        @include flexColumn(center);
        font-size: 36px;
        color: $main-red;
    }

    &__counter-text{
        width: 100%;
        height: 50px;
        // background-color: plum;
        margin-top: 10px;
        text-align: center;
        font-size: 16px;
        color: $main-dark;
    }
}

.why-section{
    width: 100%;
    padding: 50px 0px 50px 0px;
    // height: 500px;
    background-color: rgb(236, 236, 236);
    margin-top: 150px;
    @include flexRow(center);

    &__container{
        width: 1180px;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__pluses-container{
        width: 100%;
        // height: 300px;
        // background-color: pink;
        margin-top: 30px;
        @include flexRow(center);
        flex-wrap: wrap;
        align-items: center;
        // flex-wrap: wrap;
    }

    &__plus-block{
        margin: 0px 10px;
        margin-bottom: 30px;
        width: 230px;
        height: 100%;
        // background-color: plum;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            transform: scale(1.1);
        }
    }

    &__plus-img-block{
        width: 100%;
        height: 70px;
        // background-color: antiquewhite;
        @include flexColumn(center);
        align-items: center;

        svg{
            max-width: 90%;
            max-height: 90%;
        }
    }

    &__plus-text{
        width: 100%;
        // height: 40px;
        // background-color: orange;
        margin-top: 8px;
        text-align: center;
        color: $main-dark;
    }
}

.calculator-section{
    width: 100%;
    // height: 500px;
    // background-color: red;
    margin-top: 150px;
    @include flexRow(center);
    // display: none;

    &__container{
        width: 1180px;
        height: 100%;
        // background-color: yellow;
    }

    &__steps-block{
        width: 100%;
        // height: 400px;
        // background-color: pink;
        @include flexColumn(flex-start);
    }
    
    &__step{
        width: 100%;
        // height: 200px;
        // background-color: plum;
        // flex-wrap: wrap;
    }
    
    &__step-header{
        margin-top: 50px;
        font-size: 27px;
        font-weight: 300;
        // background-color: orange;
    }

    &__step-choices-block{
        width: 100%;
        // height: 160px;
        // background-color: orange;
        margin-top: 30px;
        @include flexRow(center);
        flex-wrap: wrap;
        align-items: center;
        // align-items: center;
    }

    &__step-choice-block{
        width: 150px;
        height: 160px;
        // background-color: cornflowerblue;
        @include flexColumn(flex-start);
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;
        margin: 5px;

        &:hover{
            transform: scale(1.1);
            box-shadow: 0px 0px 0px 2px $main-red;
        }
    }

    &__step-choice-block_active{
        box-shadow: 0px 0px 0px 2px $main-red;
        // transform: scale(1.1);
    }

    &__step-choice-img-block{
        width: 100%;
        height: 90px;
        // background-color: coral;
        @include flexColumn(center);
        align-items: center;

        img{
            max-width: 70%;
            max-height: 70%;
        }
    }

    &__step-choice-text{
        text-align: center;
        color: $main-dark;
        margin-top: 5px;
    }

    &__label{
        // width: 400px;
        height: 40px;
        // background-color: cornflowerblue;
        @include flexRow(flex-start);
        align-items: center;
        flex-wrap: wrap;
        margin: 10px;
        // margin-bottom: 5px;
    }
    
    &__label-text{
        @include flexRow(flex-start);
        align-items: center;
        color: $main-dark;
    }

    &__label-text::before{
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        // box-shadow: 0px 0px 0px 3px $main-red;
        border: 2px solid $main-red;
        // background-color: skyblue;
        margin-right: 10px;
        border-radius: 15px;
    }
    
    &__label-checkbox{
        display: none;
    }
    
    &__label-checkbox:checked + &__label-text::before{
        // display: none;
        box-shadow: 0px 0px 0px 5px white inset;
        background-color: $main-red;
    }

    &__trash-type-select{
        // width: 200px;
        // background-color: red;
        border: none;
        outline: none;
        color: $main-dark;
        font-size: 18px;
        margin: 30px;
    }

    &__objects-quantity-block{
        // width: 420px;
        height: 40px;
        // background-color: cornflowerblue;
        @include flexRow(flex-start);
        align-items: center;
    }

    &__objects-quantity-text{
        @include flexRow(flex-start);
        align-items: center;
        color: $main-dark;
        // background-color: coral;
        margin-right: 10px;
        margin: 30px;
    }

    &__calc-button{
        min-width: 30px;
        min-height: 30px;
        background-color: $main-red;
        border-radius: 15px;
        @include flexColumn(center);
        align-items: center;
        cursor: pointer;

        &:hover{
            transform: scale(1.1);
        }
        &:active{
            transform: scale(1.2);
        }
        svg{
            width: 16px;
            height: 16px;
        }
    }

    &__objects-quantity-number{
        width: 40px;
        height: 40px;
        // background-color: rgb(177, 131, 112);
        margin: 0px 5px 0px 5px;
        color: $main-dark;
        text-align: center;
        font-size: 24px;
        @include flexColumn(center);
    }

    &__end-price-block{
        width: 100%;
        // margin: 0px 10px 0px 10px;
        min-height: 70px;
        // background-color: plum;
        margin-top: 30px;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__price-text{
        display:flex;
        flex-direction:column;
        justify-content:center;
        font-size:24px;
        margin-left:10px;
        color:#d62229
    }

    &__end-price-container{
        // width: 300px;
        // height: 100%;
        // background-color: orange;
        @include flexRow(flex-start);
        // align-items: center;
        margin: 0px 10px 0px 10px;
        margin: 0px 10px 0px 10px;
    }

    &__end-price-text{
        height: 100%;
        // background-color: cornflowerblue;
        color: $main-dark;
        font-size: 24px;
        @include flexColumn(center);
        // flex-wrap: wrap;
        // word-wrap: none;
        // min-width: 320px;
    }
    
    &__end-price{
        height: 100%;
        // background-color: cornflowerblue;
        color: $main-red;
        font-size: 24px;
        @include flexColumn(center);
        margin-left: 10px;
    }
    
    &__end-price-form{
        margin: 0px 10px 0px 10px;
        // width: 650px;
        height: 100%;
        // background-color: orange;
        @include flexRow(center);
        align-items: center;
        flex-wrap: wrap;
    }

    &__user-input{
        width: 160px;
        height: 40px;
        margin-right: 20px;
        border-radius: 5px;
        outline: none;
        padding: 0px 0px 0px 5px;
        font-size: 18px;
        border: 2px solid $main-red;
        margin: 20px;
    }

    @keyframes calcBtn {
        0%{transform: scale(1);}
        50%{transform: scale(1.05);}
    }

    &__form-button{
        width: 204px;
        height: 44px;
        border: none;
        border-radius: 5px;
        background-color: $main-dark;
        color: white;
        font-size: 18px;
        letter-spacing: 1px;
        animation: calcBtn 2.5s infinite;
        transition: 0.3s;
        cursor: pointer;

        &:hover{
            animation-play-state: paused;
            background-color: $main-red;
        }

        &:active{
            // transform: scale(0.5);
            background-color: darken($main-red, 10%);
        }
    }
}

.reviews-section{
    margin-top: 150px;
    width: 100%;
    // height: 500px;
    // background-color: red;
    @include flexRow(center);
    // display: none;

    &__container{
        width: 1180px;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        // width: 100%;
        // height: 50px;
        // background-color: pink;
        // @include flexRow(flex-start);
        // @include flexColumn(center);
        // align-items: center;
        // font-weight: 400;
        // // letter-spacing: ;
        // font-size: 30px;
        // color: $main-dark;
    }

    &__reviews-container{
        width: 100%;
        // height: 400px;
        margin-top: 40px;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
        align-items: center;
    }

    &__review-block{
        width: 300px;
        min-height: 400px;
        // background-color: plum;
        @include flexColumn(flex-start);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.315);
        padding: 5px 30px 15px 30px;
        border-radius: 15px;
        cursor: pointer;
        transition: 0.3s;
        // margin-bottom: 30px;
        margin: 0px 15px 30px 15px;

        &:hover{
            transform: scale(1.1);
        }
    }

    &__reviewer-name{
        width: 100%;
        height: 40px;
        // background-color: orange;
        @include flexColumn(center);
        font-size: 24px;
        font-weight: 300;
        color: $main-dark;
    }

    &__review-stars{
        width: 100%;
        height: 40px;
        // background-color: orange;
        // margin-top: 10px;
        @include flexRow(flex-start);
        align-items: center;
    }
    
    &__review-star{
        width: 30px;
        height: 30px;
        fill: $main-red;
        margin-right: 10px;
        &:hover{
            transform: scale(1.1);
        }
    }

    &__review-text{
        // background-color: orange;
        margin-top: 10px;
        color: $main-dark;
        line-height: 25px;
        font-size: 14px;
    }
}

.last-section{
    width: 100%;
    height: 450px;
    // background-color: red;
    margin-top: 200px;
    @include flexRow(center);
    // background-image: url(./../src/img/main-section-dark.jpg);
    // bac
    background-repeat: no-repeat;
    background-size: 100%;
    background-attachment: fixed;
    padding: 30px 0px 0px 0px;
    position: relative;
    z-index: 1;

    &__container{
        width: 1180px;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        // color: white;
        // background-color: red;
    }

    &__form-block{
        width: 100%;
        // height: 400px;
        // background-color: pink;
        margin-top: 60px;
        @include flexRow(center);
        align-items: center;
    }
    
    &__form{
        padding:  15px 15px 30px 15px;
        width: 400px;
        // height: 300px;
        // background-color: plum;
        @include flexColumn(flex-start);
        align-items: center;
        position: relative;
        background-color: white;
        // z-index: 2;

        &::before{
            position: absolute;
            content: "";
            display: block;
            background-color: $main-red;
            width: 100px;
            height: 100px;
            right: calc(100% - 98px);
            bottom: calc(100% - 98px);
            z-index: -1;
        }

        &::after{
            position: absolute;
            content: "";
            display: block;
            background-color: $main-red;
            width: 100px;
            height: 100px;
            left: calc(100% - 98px);
            top: calc(100% - 98px);
            z-index: -1;
        }
    }

    &__form-header{
        width: 100%;
        height: 50px;
        // background-color: aqua;
        text-align: center;
        color: $main-dark;
        @include flexColumn(center);
        // padding: 0px 10px 0px 10px;
    }

    &__user-input{
        width: 200px;
        height: 30px;
        margin-top: 10px;
        padding: 0px 0px 0px 5px;
        border: 1px solid $main-red;
        color: $main-dark;
        border-radius: 5px;
        outline: none;
        // font-size: 16px;
    }

    @keyframes callbackBtn {
        0%{transform: rotate(0deg);}
        50%{transform: rotate(15deg);}
        
    }

    &__send-button{
        width: 300px;
        height: 40px;
        background-color: $main-dark;
        margin-top: 15px;
        color: white;
        outline: none;
        border: none;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 5px;
        @include flexRow(center);
        align-items: center;
        position: relative;

        svg{
            width: 35px;
            height: 35px;
            // margin-right: 15px;
            position: absolute;
            left: 50px;
            // transform: rotate(10deg);
            animation: callbackBtn 0.8s infinite;
            fill: red;
            path{
            }
        }

        &:hover{
            transform: scale(1.1);
            // box-shadow: 5px 0px 0px 0px black inset,
            // 0px -5px 0px 0px black inset;
            // background-color: darken($main-dark, 10%);
        }
    }
}


.area-section{
    width: 100%;
    // height: 200px;
    padding: 50px 0px;
    // background-color: red;
    background-color: rgb(236, 236, 236);
    margin-top: 100px;
    @include flexRow(center);

    &__container{
        width: 1180px;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__areas-toggle-block{
        width: 100%;
        height: 50px;
        // background-color: pink;
        margin-top: 50px;
        @include flexRow(center);
    }

    &__main-area{
        width: 250px;
        height: 100%;
        // background-color: plum;
        text-align: center;
        @include flexColumn(center);
        color: $main-dark;
        cursor: pointer;
        transition: 0.3s;
        margin: 0px 10px 0px 10px;
        border-radius: 30px;
        &:hover{
            background-color: lighten($main-dark, 10%);
            color: white;
        }
        &:active{
            background-color: $main-dark;
        }
    }

    &__main-area_active{
        background-color: $main-dark;
        color: white;
        border-radius: 30px;
        &:hover{
            background-color: $main-dark;
        }
    }

    &__areas-block{
        width: 100%;
        // height: 500px;
        // background-color: yellow;
        margin-top: 20px;
        @include flexRow(center);
        flex-wrap: wrap;
        position: relative;
        display: none;
    }

    &__close-button{
        position: absolute;
        width: 30px;
        height: 30px;
        // background-color: red;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 17px);
        // left: 100%;
        cursor: pointer;
        
        svg{
            transition: 0.3s;
        }

        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__area-block{
        width: 150px;
        height: 70px;
        text-align: center;
        text-decoration: none;
        // background-color: fuchsia;
        background-color: white;
        margin: 15px;
        color: $main-dark;
        @include flexColumn(center);
        border-radius: 5px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.315);
        
        &:hover{
            transform: scale(1.1);
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.411);
        }
    }
}

.map-section{
    width: 100%;
    height: 550px;
    background-color: rgb(236, 236, 236);
    position: relative;
    
    &__contacts-block{
        // width: 300px;
        // height: 270px;
        padding: 20px 20px 20px 20px;
        // background-color: yellow;
        background-color: white;
        position: absolute;
        z-index: 1;
        left: 100px;
        top: 60px;
        border-radius: 15px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.315);
        @include flexColumn(flex-start);
    }
    
    &__contact-block{
        width: 100%;
        height: 40px;
        // background-color: pink;
        @include flexRow(flex-start);
        align-items: center;
        margin-bottom: 5px;
        color: $main-dark;
        
        svg{
            width: 30px;
            height: 30px;
        }
    }
    
    &__link{
        @include flexRow(flex-start);
        width: 100%;
        height: 100%;
        // background-color: red;
        align-items: center;
        color: $main-dark;
        text-decoration: none;
        transition: 0.3s;

        &:hover{
            transform: scale(1.1);
        }
    }

    &__contact-text{
        margin-left: 10px;
    }
    
    &__map-block{
        width: 100%;
        height: 100%;
        // z-index: 100;
        position: absolute;
        // box-shadow: 0px 10px 0px 0px black inset;
        // filter: blur(1px);
        // filter: saturate(250%);
        // filter: blur(1px);
    }

}

.site-footer{
    width: 100%;
    min-height: 100px;
    background-color: $main-dark;
    // background-color: red;
    padding: 30px 0px 0px 0px;
    @include flexRow(center);

    &__container{
        width: 1180px;
        height: 100%;
        // background-color: yellow;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__menu-block{
        height: 100%;
        // background-color: pink;
        @include flexColumn(center);
        margin-top: 30px;
        margin: 30px 20px 0px 20px;
    }

    &__menu-block.logo-block{
        margin-top: 16px;
        // background-color: red;
        // height: 100%;
    }
    
    &__menu-block.nav-block{
        @include flexRow(center);
        align-items: center;
        flex-wrap: wrap;
    }

    &__menu-item{
        margin: 0px 15px;
        height: 100%;
        // background-color: plum;
        @include flexColumn(center);
        text-decoration: none;
        color: white;
        margin-bottom: 15px;
        // margin-bottom: 30px;
        p{
            transition: 0.3s;
        }

        &:first-child{
            margin-left: 0px;
        }

        &:last-child{
            margin-right: 0px;
        }

        &:hover{
            p{
                transform: scale(1.1);
            }
        }
    }

    &__menu-separator{
        width: 1px;
        height: 40%;
        background-color: lightgray;
    }

    &__author-link{
        color: lightgray;
        text-decoration: none;
        text-align: center;
        margin-bottom: 20px;
    }

}


.modal-window{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.37);
    position: absolute;
    z-index: 99999;
    @include flexRow(center);
    align-items: center;
    display: none;

    &__form{
        padding: 30px;
        // width: 500px;
        // height: 400px;
        background-color: white;
        border-radius: 5px;
        @include flexColumn(flex-start);
        align-items: center;
        position: relative;
    }

    &__close-button{
        position: absolute;
        // background-color: red;
        width: 20px;
        height: 20px;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 25px);
        bottom: calc(100% - 25px);
        cursor: pointer;
        svg{
            transition: 0.3s;
        }
        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__header{
        font-size: 20px;
        width: 200px;
    }

    &__user-input{
        margin-top: 20px;
        width: 200px;
        height: 30px;
        border: 1px solid $main-red;
        border-radius: 5px;
        padding: 0px 0px 0px 5px;
        outline: none;
        font-size: 18px;
        font-weight: 300;
    }

    &__button{
        width: 210px;
        height: 40px;
        background-color: $main-dark;
        margin-top: 15px;
        color: white;
        border: none;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;

        &:hover{
            background-color: $main-red;
        }
    }

    &__policy-text{
        width: 210px;
        margin-top: 10px;
        font-size: 12px;
        text-align: center;
        color: lightgrey;
    }
}

.up-button{
    width:100px;
    height:100px;
    border-radius:100px 0px 0px 100px;
    background-color:#3f4f5e;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    position:fixed;
    top:calc(95% - 100px);
    left:calc(100% - 100px);
    z-index:100;display:none
}
.up-button .modal-button__svg-container{
    width:65px;
    height:65px;
    border-radius:65px;
    background-color:#fff;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center
}
.up-button svg{
    background-color:#fff;
    width:40px;
    height:40px
}

.up-button:hover .modal-button__svg-container{
    transform:scale(1.1)
}

.up-button:hover svg{
    transform:scale(1.1)
}

.up-button:active .modal-button__svg-container{
    transform:scale(1.1)
}

.up-button:active svg{
    transform:scale(1.1)
}

@-webkit-keyframes modalCallAnim{
    0%{transform:rotate(0deg)}
    50%{transform:rotate(10deg)}
    100%{transform:rotate(0deg)}
}

@keyframes modalCallAnim{
    0%{transform:rotate(0deg)}
    50%{transform:rotate(10deg)}
    100%{transform:rotate(0deg)}
}

.call-button{
    width:100px;
    height:100px;
    border-radius:0px 100px 100px 0px;
    background-color:#d62229;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    position:fixed;
    top:calc(95% - 100px);
    right:calc(100% - 100px);
    z-index:100;display:none
}

.call-button .modal-button__svg-container{
    width:65px;
    height:65px;
    border-radius:65px;
    background-color:#fff;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center
}

.call-button svg{
    -webkit-animation:modalCallAnim 1s infinite;animation:modalCallAnim 1s infinite;
    background-color:#fff;
    width:40px;
    height:40px
}

.call-button:hover .modal-button__svg-container{
    transform:scale(1.1)
}

.call-button:hover svg{
    -webkit-animation-play-state:paused;
    animation-play-state:paused;
    transform:scale(1.1)
}

.call-button:active .modal-button__svg-container{
    transform:scale(1.1)
}

.call-button:active svg{
    transform:scale(1.1)
}

.error{
    color:#3f4f5e;
    font-weight:300;
    margin:5px
}

.error-message{
    width:100%;
    height:900px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    color:#3f4f5e;
    font-size:28px;
    text-align:center
}

.error-message a{
    text-align:center;
    margin-top:20px;
    color:#d62229;
    font-size:30px
}

.error-links{
    // background-color: pink;
    @include flexColumn(flex-start);
}

.error-link{
    font-size: 16px !important;
    text-decoration: none;
    // margin-bottom: 10px !important;
    margin-top: 10px !important;
    &:hover{
        text-decoration: underline;
    }
}

.sitemap-section{
    width: 100%;
    // height: 100%;
    // background-color: red;
    @include flexRow(center);
    padding: 30px 0px 30px 0px;

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        font-size: 36px;
        color: $main-dark;
        // margin-bottom: 30px;
    }

    &__lists-container{
        // background-color: pink;
        margin-top: 10px;
        @include flexRow(center);   
    }

    &__menu{
        // background-color: plum;
        margin: 10px;
        @include flexColumn(flex-start);
    }

    &__submenu{
        margin-left: 10px;
        // background-color: orange;
        margin-top: 5px;
    }

    &__submenu-item{
        font-size: 14px;
        margin-top: 5px;
    }

    &__submenu-link{
        &:hover{
            color: $main-red;
            text-decoration: none;
        }
    }
}

.links-section{
    width: 100%;
    // height: 400px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        text-align: center;
        font-size: 36px;
        color: $main-dark;
        // background-color: pink;
    }

    &__links-container{
        width: 100%;
        @include flexRow(center);
        flex-wrap: wrap;
        // background-color: pink;
        margin-top: 15px;
    }

    &__link{
        margin: 10px;
        padding: 10px;
        border: 1px solid $main-red;
        border-radius: 5px;
        color: $main-red;
        cursor: pointer;
        text-decoration: none;
        transition: 0.3s;
        &:hover{
            background-color: $main-red;
            color: white;
        }
    }
}

@media (max-width: 1180px){

    .container{
        // padding: 0px 10px 0px 10px !important;
        width: 90%;
    }

    .site-header{
        // display: none;
        // background-color: red;
        height: unset;
        padding: 20px 0px 20px 0px;
        @include flexRow(center);


        &__container{
            // background-color: yellow;
            @include flexColumn(flex-start);
            align-items: center;
            // padding: 0px 100px;
        }
        
        &__nav{
            @include flexColumn(flex-start);
            align-items: center;

        }

        &__mobile-button{
            display: flex;
            order: 2;
            margin-top: 10px;
            box-shadow: 0px 0px 0px 2px $main-red;
            height: 40px;
            @include flexColumn(center);
            border-radius: 5px;

            svg{
                path{
                    stroke: $main-dark;
                }
            }
        }

        &__menu{
            // display: none;
            position: absolute;
            background-color: white;
            height: unset;
            width: 100%;
            margin-top: 149px;
            @include flexColumn(flex-start);
            align-items: center;
            padding: 0px 0px 20px 0px;
            display: none;
            z-index: 10;
        }

        &__menu-item{
            margin: 0px 0px 10px 0px !important;
            
        }
    }

    .main-section{
        // display: none;
        // padding: 10px;
        &__description-text{
            width: inherit;
        }
        
    }

    .counters-section{
        // display: none;
        // background-color: red;
        flex-wrap: wrap;
        
        &__counters-container{
            // background-color: yellow;
            // flex-wrap: wrap;
            // align-items: center;
        }

        &__counter-block{
            // background-color: pink;
            // width: 100%;
            // margin-bottom: 30px;
        }
    }

    .calculator-section{
        // display: none;
    }

    .why-section{
        // display: none;
        &__plus-block{
            // width: 100%;
            // margin-bottom: 30px;
        }
    }

    .reviews-section{
        // display: none;
    }

    .last-section{
        // display: none;
        &__form{
            width: 100%;
            padding: 0px;

            &::before{
                display: none;
            }

            &::after{
                display: none;
            }
        }

        &__send-button{
            // width: 300px;
        }
    }

    .area-section{
        // display: none;
    }
    
    .map-section{
        // display: none;
        height: unset;
        // background-color: red;
        @include flexRow(center);
        flex-wrap: wrap;
        
        &__contacts-block{
            position: relative;
            left: 0px;
            top: 0px;
            border-radius: 0px;
            box-shadow: unset;
            background-color: transparent;
        }

        &__map-block{
            position: relative;
            width: 100%;
            height: 400px;
        }

    }

    .site-footer{
        // display: none;

        &__menu-separator{
            display: none;
        }
    }
}